/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-prototype-builtins */
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import PropTypes from 'prop-types';
import React from 'react';
import useAlertBox from '@utils/useAlertBox';
import { Col, Container, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import cx from 'classnames';
import ContentCard from '@components/ContentCard';
import Iframe from 'react-iframe';
import SponsorPlug from '@components/SponsorPlug';
import CardButton from '@components/CardButton';
import HeadSEO from '@components/Navigation/HeadSEO';
import SponsorLockup from '@components/SponsorLockup';
import MediaGallery from '@components/MediaGallery';
import { getHeadProps } from '@utils/navigationHelpers';
import CTABanner from '@components/CTAContentful';
import BannerCredit from '@components/BannerCredit';
import cardSwitch from '@utils/card-type-switch';
import withNullChecks from '@templates/HOC/withNullChecks';
import StandardPageWrapper from '@templates/StandardPageWrapper';
import Breadcrumbs from '@components/Navigation/Breadcrumbs';
import Title from '@components/Title';
import rendererOptions from '../../../richtext-rendering/renderer-options';

import './index.scss';

export const pageQuery = graphql`
  query landingPageSections($slug: String!) {
    landingPage: contentfulLandingPageSections(slug: { eq: $slug }) {
      banner {
        title
        description
        file {
          url
        }
        gatsbyImageData(layout: CONSTRAINED, width: 960)
      }
      bannerCredit
      slug
      titleDisplay
      titleSeo
      descriptionSeo
      keywordsSeo
      titleBreadcrumb
      sections {
        ... on ContentfulPageSection {
          copy {
            raw
            references {
              ...RichTextReferences
            }
          }
          cards {
            ...AllCardTypes
          }
        }
        ... on ContentfulCardLink {
          ...AllCardTypes
        }
        ... on ContentfulCardAsset {
          ...AllCardTypes
        }
        ... on ContentfulArticle {
          ...AllCardTypes
        }

      }
      date
      category
      sponsorText
      sponsorLogos {
        url
        width
        logoImage {
          description
          gatsbyImageData
          file {
            url
          }
        }
      }
      sponsorLockupText1
      sponsorLockupLogo1 {
        url
        width
        title
        logoImage {
          description
          gatsbyImageData(height: 60, layout: FIXED)
          file {
            url
          }
        }
        newTab
      }
      sponsorLockupText2
      sponsorLockupLogo2 {
        url
        width
        title
        logoImage {
          description
          gatsbyImageData(height: 60, layout: FIXED)
          file {
            url
          }
        }
        newTab
      }
      sponsorLockupText3
      sponsorLockupLogo3 {
        url
        width
        title
        logoImage {
          description
          gatsbyImageData(height: 60, layout: FIXED)
          file {
            url
          }
        }
        newTab
      }
      sponsorLockupText4
      sponsorLockupLogo4 {
        url
        width
        title
        logoImage {
          description
          gatsbyImageData(height: 60, layout: FIXED)
          file {
            url
          }
        }
        newTab
      }
      actions {
        title
        heading
        copy
        buttonText
        buttonUrl
        newTab
      }
      mediaGallery {
        ...MediaGalleryTypes
      }
    }
    breadCrumbs: contentfulLandingPageSections(slug: { eq: $slug }) {
      titleBreadcrumb
      parentPage {
        ...ParentPageRecursive
      }
    }
    contentfulHomePage ( titleDisplay: { eq: "Home Page"} ) {
      alertBox {
        raw
        references {
          ...RichTextReferences
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const { landingPage } = data;
  const {
    pageTitle, pageDescription, pageKeywords, pageImgUrl, pageAltText,
  } = getHeadProps(landingPage);

  return (
    <HeadSEO
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      pageKeywords={pageKeywords}
      pageImgUrl={pageImgUrl}
      pageAltText={pageAltText}
    />
  );
};

const SectionsLandingPageTemplate = ({ data }) => {
  const { landingPage, breadCrumbs } = data;
  const sponsorList = landingPage.sponsorLogos
    ? landingPage.sponsorLogos.map((logo) => (
      <SponsorPlug key={uuidv4()} logo={logo} />
    ))
    : null;
  // This is a short term hack to embed a component of our
  // Science is All of is work into a specific page
  // using an Iframe. This should be abstracted to the CMS in
  // the future. The IFrame content is a Unity WebGL player
  // that isn't currently supported by our systems.
  const bustedHack = data.landingPage.slug === 'exhibits-experiences/race/busted';

  let row3 = null;
  let cardBox = [];

  const { alertBoxData, hasAlert } = useAlertBox(data);

  return (
    <StandardPageWrapper alertBoxData={alertBoxData} hasAlert={hasAlert}>
      <>
        <Breadcrumbs parentData={breadCrumbs} />
        <Container className={cx('landing-sections', 'p-0', 'mb-4', 'mt-0')}>
          <Row>
            {landingPage.banner && (
              <Col md={12}>
                {
                  bustedHack
                    ? (
                      <Iframe
                        url="https://storage.googleapis.com/busted-interactive/index.html"
                        frameBorder="0"
                        width="1110"
                        height="580"
                        id="bustedInteractive"
                        styles={{ border: 0 }}
                      />
                    )
                    : (
                      <>
                        {landingPage.bannerCredit && (
                          <BannerCredit text={landingPage.bannerCredit} />
                        )}
                        <GatsbyImage
                          alt={landingPage.banner.description}
                          className="d-block mx-auto"
                          data-cy="gatsby-img"
                          image={getImage(landingPage.banner)}
                        />
                      </>
                    )
                }
              </Col>
            )}
            <Col md={12}>
              <Title title={landingPage.titleDisplay} titleSize="h1" classes="article-title mb-4 mt-3 text-underline text-underline-color-primary" cypressDataAttr="content-title" />
              <div>
                {landingPage.sponsorText && <span>{landingPage.sponsorText}</span>}
                {sponsorList}
              </div>
            </Col>
          </Row>
          {landingPage.sections
          && landingPage.sections.map((section, index, sections) => {
            let borderBottomDef;
            if (index > 0 && index < landingPage.sections.length) {
              borderBottomDef = '0';
            }
            if (index === landingPage.sections.length - 1) {
              borderBottomDef = '1px solid #ddd';
            }
            const hasCopy = section.hasOwnProperty('copy');

            // Page Section entries
            if (hasCopy) {
              return (
                <Row
                  className={index !== 0 ? 'container-border' : ''}
                  style={{
                    borderBottom: borderBottomDef,
                    paddingLeft: '20px',
                    paddingRight: '20px',
                  }}
                  key={uuidv4()}
                >
                  <Col sm={12} style={{ paddingLeft: '20px' }}>
                    {section.copy
                    && renderRichText(section.copy, rendererOptions)}
                  </Col>
                  {section.cards
                  && section.cards.map((card) => (
                    <Col md={4} lg={4} key={uuidv4()}>
                      {card.__typename === 'ContentfulContentCard' ? (
                        <ContentCard
                          title={
                              card.titleDisplay ? card.titleDisplay : ''
                            }
                          body={card.body}
                          button={card.button}
                          imgSrc={card.cardImage}
                          border={card.border}
                        />
                      ) : (
                        <CardButton
                          imgSrc={card.banner}
                          body={card.tagline}
                          link={cardSwitch(card)[0]}
                          title={card.titleDisplay}
                          titleSize="h3"
                          tag={cardSwitch(card)[1]}
                          altText={
                            card.banner ? card.banner.description : ''
                          }
                          topics={card.topics}
                        />
                      )}
                    </Col>
                  ))}
                </Row>
              );
            }
            if (section.__typename === 'ContentfulContentCard') {
              cardBox.push(
                <Col md={4} lg={4} key={uuidv4()}>
                  <ContentCard
                    title={
                      section.titleDisplay ? section.titleDisplay : ''
                    }
                    body={section.body}
                    button={section.button}
                    imgSrc={section.cardImage}
                    border={section.border}
                  />
                </Col>,
              );
              row3 = (
                <Row
                  className={index !== 0 ? 'container-border' : ''}
                  style={{
                    borderBottom: borderBottomDef,
                    paddingLeft: '20px',
                    paddingRight: '20px',
                  }}
                >
                  {cardBox.map((element) => element)}
                </Row>
              );

              if (
                cardBox.length === 3
                  || (sections[index + 1] && sections[index + 1].hasOwnProperty('copy'))
              ) {
                cardBox = [];
                return row3;
              }
              if (
                (
                  cardBox.length === 1
                    && sections[index + 1] === undefined
                )
                  || (
                    cardBox.length === 2 && sections[index + 1] === undefined
                  )
              ) {
                cardBox = [];
                return row3;
              }
            } else {
              cardBox.push(
                <Col md={4} lg={4} key={uuidv4()}>
                  <CardButton
                    imgSrc={section.banner}
                    body={section.tagline}
                    link={cardSwitch(section)[0]}
                    title={section.titleDisplay}
                    titleSize="h2"
                    tag={cardSwitch(section)[1]}
                    altText={
                      section.banner ? section.banner.description : ''
                    }
                    topics={section.topics}
                    newTab={section.newTab}
                  />
                </Col>,
              );
              row3 = (
                <Row
                  className={index !== 0 ? 'container-border' : ''}
                  style={{
                    borderBottom: borderBottomDef,
                    paddingLeft: '20px',
                    paddingRight: '20px',
                  }}
                >
                  {cardBox.map((element) => element)}
                </Row>
              );

              if (
                cardBox.length === 3
                  || (sections[index + 1] && sections[index + 1].hasOwnProperty('copy'))
              ) {
                cardBox = [];
                return row3;
              }

              if (
                (cardBox.length === 1 && sections[index + 1] === undefined)
                  || (cardBox.length === 2 && sections[index + 1] === undefined)
              ) {
                cardBox = [];
                return row3;
              }
            }
          })}
        </Container>
        {(landingPage.mediaGallery && <MediaGallery page={landingPage} />)}
        {(landingPage.sponsorLockupText1
          || landingPage.sponsorLockupText2
          || landingPage.sponsorLockupText3
          || landingPage.sponsorLockupText4
          || landingPage.sponsorLockupLogo1
          || landingPage.sponsorLockupLogo2
          || landingPage.sponsorLockupLogo3
          || landingPage.sponsorLockupLogo4)
        && <SponsorLockup page={landingPage} />}
        {landingPage.actions && <CTABanner actions={landingPage.actions} />}
      </>
    </StandardPageWrapper>
  );
};

SectionsLandingPageTemplate.propTypes = {
  // TODO: Fix this eslint warning and remove whitelisting
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default withNullChecks({
  dataKey: 'landingPage',
  typeName: 'landingPageSections',
})(SectionsLandingPageTemplate);
